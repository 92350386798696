import EventBus from '@vertx/eventbus-bridge-client.js';
import { v as bvVar } from '../../bv/vars';

const eventBus = new EventBus(window.VCSTATE.PUSH_GATEWAY_EVENT_BUS_URL);
eventBus.enableReconnect(true);

let eventBusPromise;
const storedHandlers = {};

const createEventBusPromise = () => {
  eventBusPromise = new Promise((resolve) => {
    eventBus.onopen = () => {
      eventBus.send('/client-connected', {
        locale: bvVar('locale'),
        brand: bvVar('brandId'),
      });

      Object.entries(storedHandlers).forEach(([address, addressHandlers]) => {
        addressHandlers.forEach((handler) => {
          eventBus.registerHandler(address, handler);
        });
      });

      resolve(eventBus);
    };
  });
};

eventBus.onclose = () => {
  createEventBusPromise();
};

createEventBusPromise();

const registerMethod = (address, handler) => {
  if (!storedHandlers[address]) {
    storedHandlers[address] = [];
  }

  if (!storedHandlers[address].includes(handler)) {
    storedHandlers[address].push(handler);
  }
};

const unregisterMethod = (address, handler) => {
  if (!storedHandlers[address]) return;

  storedHandlers[address] = storedHandlers[address].filter((h) => h !== handler);

  if (storedHandlers[address].length === 0) {
    delete storedHandlers[address];
  }
};

export default (method) => (...args) => {
  if (method === 'registerHandler') registerMethod(...args);
  if (method === 'unregisterHandler') unregisterMethod(...args);

  eventBusPromise.then(() => { eventBus[method](...args); });
};
