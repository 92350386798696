import withEventBus from './with_event_bus';

const registerHandler = withEventBus('registerHandler');
const unregisterHandler = withEventBus('unregisterHandler');
const send = withEventBus('send');

export {
  registerHandler,
  unregisterHandler,
  send,
};
